var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { unref, customRef, toRaw, shallowRef, triggerRef, ref, isRef, watch, readonly, inject, toRefs, reactive, computed, defineComponent, onMounted, createElementBlock, openBlock, toDisplayString, onBeforeUnmount, mergeDefaults, createBlock, Teleport, mergeProps, Fragment, renderList, normalizeStyle, renderSlot, createCommentVNode, withCtx, normalizeProps, guardReactiveProps, getCurrentInstance, provide, createVNode, toRef, shallowReactive, nextTick, createElementVNode, markRaw, normalizeClass, Transition, resolveDynamicComponent } from "vue";
const CLASS_PREFIX = "Notivue__";
const DEFAULT_DURATION = 6e3;
const NotificationTypeKeys = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
  PROMISE: "promise",
  PROMISE_RESOLVE: "promise-resolve",
  PROMISE_REJECT: "promise-reject"
};
const success = {
  title: "",
  message: "",
  duration: DEFAULT_DURATION,
  ariaLive: "polite",
  ariaRole: "status"
};
const error = __spreadProps(__spreadValues({}, success), {
  ariaLive: "assertive",
  ariaRole: "alert"
});
const promise = __spreadProps(__spreadValues({}, success), {
  duration: Infinity
});
const warning = __spreadProps(__spreadValues({}, error), {
  ariaLive: "polite"
});
const info = __spreadValues({}, success);
const DEFAULT_NOTIFICATION_OPTIONS = {
  [NotificationTypeKeys.SUCCESS]: success,
  [NotificationTypeKeys.ERROR]: error,
  [NotificationTypeKeys.WARNING]: warning,
  [NotificationTypeKeys.INFO]: info,
  [NotificationTypeKeys.PROMISE]: promise,
  [NotificationTypeKeys.PROMISE_RESOLVE]: success,
  [NotificationTypeKeys.PROMISE_REJECT]: error
};
const DEFAULT_CONFIG = {
  pauseOnHover: true,
  pauseOnTouch: true,
  pauseOnTabChange: true,
  enqueue: false,
  position: "top-center",
  teleportTo: "body",
  notifications: DEFAULT_NOTIFICATION_OPTIONS,
  limit: Infinity,
  avoidDuplicates: false,
  transition: "transform 0.35s cubic-bezier(0.5, 1, 0.25, 1)",
  animations: {
    enter: CLASS_PREFIX + "enter",
    leave: CLASS_PREFIX + "leave",
    clearAll: CLASS_PREFIX + "clearAll"
  }
};
const push$1 = createPushMock();
function setPush(p) {
  Object.assign(push$1, p);
}
function createPush(proxies) {
  let createCount = 0;
  function push2(options, type, id = `${createCount++}`) {
    if (typeof unref(options) === "string") {
      options = { message: options };
    }
    proxies.push(__spreadProps(__spreadValues({}, options), { id, type }));
    return {
      id,
      clear: () => proxies.clear(id),
      destroy: () => proxies.clear(id, { isDestroy: true })
    };
  }
  return {
    success: (options) => push2(options, NotificationTypeKeys.SUCCESS),
    error: (options) => push2(options, NotificationTypeKeys.ERROR),
    warning: (options) => push2(options, NotificationTypeKeys.WARNING),
    info: (options) => push2(options, NotificationTypeKeys.INFO),
    promise: (options) => {
      const { id, clear, destroy } = push2(options, NotificationTypeKeys.PROMISE);
      return {
        resolve: (options2) => push2(options2, NotificationTypeKeys.PROMISE_RESOLVE, id),
        reject: (options2) => push2(options2, NotificationTypeKeys.PROMISE_REJECT, id),
        success: (options2) => push2(options2, NotificationTypeKeys.PROMISE_RESOLVE, id),
        error: (options2) => push2(options2, NotificationTypeKeys.PROMISE_REJECT, id),
        clear,
        destroy
      };
    },
    load(options) {
      return this.promise(options);
    },
    clearAll: () => proxies.clearAll(),
    destroyAll: () => proxies.destroyAll()
  };
}
function createPushMock() {
  const noop = new Proxy({}, { get: () => () => {
  } });
  return createPush(noop);
}
function pushEvent(detail) {
  const eventId = Date.now();
  let pushResult = {};
  const resultEventName = `notivue:id:${eventId}`;
  window.addEventListener(
    resultEventName,
    (e) => {
      pushResult = e.detail;
    },
    { once: true }
  );
  window.dispatchEvent(
    new CustomEvent("notivue:push", {
      detail: __spreadProps(__spreadValues({}, detail), {
        type: detail.type,
        resultEventName
      })
    })
  );
  return pushResult;
}
const push = {
  success: (options) => pushEvent(__spreadProps(__spreadValues({}, options), { type: "success" })),
  info: (options) => pushEvent(__spreadProps(__spreadValues({}, options), { type: "info" })),
  error: (options) => pushEvent(__spreadProps(__spreadValues({}, options), { type: "error" })),
  warning: (options) => pushEvent(__spreadProps(__spreadValues({}, options), { type: "warning" })),
  promise: (options) => pushEvent(__spreadProps(__spreadValues({}, options), { type: "promise" })),
  load: (options) => pushEvent(__spreadProps(__spreadValues({}, options), { type: "promise" })),
  clearAll() {
    window.dispatchEvent(new CustomEvent("notivue:clear-all"));
  },
  destroyAll() {
    window.dispatchEvent(new CustomEvent("notivue:destroy-all"));
  }
};
const isSSR = typeof window === "undefined";
function mergeDeep(target, source) {
  const merged = __spreadValues({}, target);
  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      if (isPlainObject(source[key])) {
        merged[key] = mergeDeep(target[key], source[key]);
      } else {
        merged[key] = source[key];
      }
    }
  }
  return merged;
}
function mergeNotificationOptions(configOptions, pushOptions) {
  pushOptions.props || (pushOptions.props = {});
  return __spreadValues(__spreadValues(__spreadValues(__spreadValues({}, configOptions[pushOptions.type]), configOptions.global), pushOptions), pushOptions.type === "promise" ? { duration: Infinity } : {});
}
function isPlainObject(value) {
  if (Object.prototype.toString.call(value) !== "[object Object]") {
    return false;
  }
  const prototype = Object.getPrototypeOf(value);
  return prototype === null || Object.getPrototypeOf(prototype) === null;
}
function createConfigRefs(target, source, isRunning) {
  const conf = mergeDeep(target, source);
  function configRef(value) {
    return customRef((track, trigger) => ({
      get() {
        track();
        return value;
      },
      set(newValue) {
        if (!isRunning.value) return;
        value = newValue;
        trigger();
      }
    }));
  }
  for (const key in conf) conf[key] = configRef(conf[key]);
  return conf;
}
function toRawConfig(config) {
  return Object.entries(config).reduce(
    (acc, [key, { value }]) => __spreadProps(__spreadValues({}, acc), { [key]: toRaw(value) }),
    {}
  );
}
const isStatic = (type) => type === NotificationTypeKeys.SUCCESS || type === NotificationTypeKeys.ERROR || type === NotificationTypeKeys.WARNING || type === NotificationTypeKeys.INFO;
const internalKeys = [
  "timeout",
  "resumedAt",
  "remaining",
  // Maybe in future releases these could be exposed
  "animationAttrs",
  "positionStyles"
];
function getSlotItem(item) {
  return Object.fromEntries(
    Object.entries(item).filter(([key]) => !internalKeys.includes(key))
  );
}
let updateConfig = () => {
};
function createStore(userConfig, isRunning) {
  const config = createConfig(userConfig, isRunning);
  const queue = createQueue();
  const items = createItems(config, queue);
  const elements = createElements();
  const animations = createAnimations(config, items, queue, elements);
  const timeouts = createTimeouts(items, animations);
  return { config, queue, items, elements, animations, timeouts };
}
function createConfig(userConfig, isRunning) {
  const config = createConfigRefs(DEFAULT_CONFIG, userConfig, isRunning);
  function update(newConfig) {
    if (!isRunning.value) return;
    if (typeof newConfig === "function") {
      newConfig = newConfig(toRawConfig(config));
    }
    for (const key in newConfig) {
      if (typeof config[key].value === "object") {
        config[key].value = mergeDeep(config[key].value, newConfig[key]);
      } else {
        config[key].value = newConfig[key];
      }
    }
  }
  updateConfig = update;
  return __spreadProps(__spreadValues({}, config), { update });
}
function createQueue() {
  return {
    entries: shallowRef([]),
    get length() {
      return this.entries.value.length;
    },
    add(item) {
      this.entries.value.push(item);
      this.triggerRef();
    },
    get(id) {
      return this.entries.value.find((e) => e.id === id);
    },
    update(id, newOptions) {
      const entry = this.get(id);
      if (entry) Object.assign(entry, newOptions);
    },
    remove(id) {
      this.entries.value = this.entries.value.filter((e) => e.id !== id);
    },
    triggerRef() {
      triggerRef(this.entries);
    },
    clear() {
      this.entries.value = [];
    }
  };
}
function createItems(config, queue) {
  return {
    entries: shallowRef([]),
    get length() {
      return this.entries.value.length;
    },
    lifecycleEventsCount: ref(0),
    addLifecycleEvent() {
      this.lifecycleEventsCount.value++;
    },
    clearLifecycleEvents() {
      this.lifecycleEventsCount.value = 0;
    },
    add(item) {
      this.entries.value.unshift(item);
      this.triggerRef();
      this.addLifecycleEvent();
    },
    addFromQueue() {
      const next = __spreadProps(__spreadValues({}, queue.entries.value[0]), {
        timeout: queue.entries.value[0].timeout(),
        createdAt: Date.now()
      });
      queue.remove(next.id);
      this.add(next);
    },
    findDupe(item) {
      return this.entries.value.find(
        (e) => unref(e.message).replace(/\uFEFF/g, "") === unref(item.message).replace(/\uFEFF/g, "") && // prettier-ignore
        unref(e.title) === unref(item.title) && e.type === item.type
      );
    },
    get(id) {
      return this.entries.value.find((e) => e.id === id);
    },
    update(id, newOptions) {
      const entry = this.get(id);
      if (entry) Object.assign(entry, newOptions);
    },
    triggerRef() {
      triggerRef(this.entries);
    },
    updateAll(updateFn) {
      this.entries.value = this.entries.value.map(updateFn);
    },
    remove(id) {
      this.entries.value = this.entries.value.filter((e) => e.id !== id);
      const shouldDequeue = queue.length > 0 && this.length < config.limit.value;
      if (shouldDequeue) this.addFromQueue();
    },
    clear() {
      this.entries.value = [];
    }
  };
}
function createElements() {
  return {
    root: ref(null),
    rootAttrs: shallowRef({}),
    setRootAttrs(newAttrs) {
      this.rootAttrs.value = newAttrs;
    },
    items: ref([]),
    getSortedItems() {
      return this.items.value.sort((a, b) => +b.dataset.notivueItem - +a.dataset.notivueItem);
    },
    containers: ref([])
  };
}
function createAnimations(config, items, queue, elements) {
  return {
    isReducedMotion: ref(false),
    setReducedMotion(newVal) {
      this.isReducedMotion.value = newVal;
    },
    playLeave(id, { isDestroy = false, isUserTriggered = false } = {}) {
      const { leave = "" } = config.animations.value;
      const item = items.get(id);
      window.clearTimeout(item == null ? void 0 : item.timeout);
      const onAnimationend = (e) => {
        var _a;
        if (e && e.currentTarget !== e.target) return;
        (_a = item == null ? void 0 : item[isUserTriggered ? "onManualClear" : "onAutoClear"]) == null ? void 0 : _a.call(item, getSlotItem(item));
        items.remove(id);
      };
      if (!item || !leave || isDestroy || this.isReducedMotion.value) {
        items.addLifecycleEvent();
        return onAnimationend();
      }
      items.update(id, {
        positionStyles: __spreadProps(__spreadValues({}, item.positionStyles), {
          zIndex: -1
        }),
        animationAttrs: {
          class: leave,
          onAnimationend
        }
      });
      items.addLifecycleEvent();
    },
    playClearAll() {
      items.entries.value.forEach((e) => window.clearTimeout(e.timeout));
      const { clearAll = "" } = config.animations.value;
      const onAnimationend = () => {
        queue.clear();
        items.clear();
      };
      if (!clearAll || this.isReducedMotion.value) return onAnimationend();
      elements.setRootAttrs({
        class: clearAll,
        onAnimationend
      });
    },
    updatePositions({ isImmediate = false } = {}) {
      const isReduced = this.isReducedMotion.value || isImmediate;
      const isTopAlign = config.position.value.indexOf("top") === 0;
      const leaveClass = config.animations.value.leave;
      let accPrevHeights = 0;
      for (const el of elements.getSortedItems()) {
        const id = el.dataset.notivueItem;
        const item = items.get(id);
        if (!el || !item || item.animationAttrs.class === leaveClass) continue;
        items.update(id, {
          positionStyles: {
            transform: `translate3d(0, ${accPrevHeights}px, 0)`,
            transition: isReduced ? "none" : config.transition.value
          }
        });
        accPrevHeights += (isTopAlign ? 1 : -1) * el.clientHeight;
      }
      items.triggerRef();
    }
  };
}
function createTimeouts(items, animations) {
  return {
    isStreamPaused: ref(false),
    isStreamFocused: ref(false),
    debounceTimeout: void 0,
    setStreamPause(newVal = true) {
      this.isStreamPaused.value = newVal;
    },
    setStreamFocus(newVal = true) {
      this.isStreamFocused.value = newVal;
    },
    clearDebounceTimeout() {
      window.clearTimeout(this.debounceTimeout);
    },
    reset() {
      this.clearDebounceTimeout();
      this.setStreamPause(false);
      this.setStreamFocus(false);
    },
    create(id, duration) {
      if (this.isStreamPaused.value) return;
      if (Number.isFinite(duration) && duration > 0) {
        return window.setTimeout(() => animations.playLeave(id), duration);
      }
    },
    pause() {
      if (items.length === 0 || this.isStreamPaused.value) return;
      this.setStreamPause();
      items.updateAll((item) => {
        var _a;
        window.clearTimeout(item.timeout);
        if (item.duration === Infinity) return item;
        let remaining = 0;
        if (item.remaining == null) {
          remaining = item.duration - (Date.now() - ((_a = item.resumedAt) != null ? _a : item.createdAt));
        } else {
          remaining = item.remaining - (Date.now() - item.resumedAt);
        }
        return __spreadProps(__spreadValues({}, item), {
          remaining
        });
      });
    },
    resume() {
      if (items.length === 0 || !this.isStreamPaused.value) return;
      this.setStreamPause(false);
      items.updateAll((item) => {
        var _a;
        window.clearTimeout(item.timeout);
        if (item.duration === Infinity) return item;
        return __spreadProps(__spreadValues({}, item), {
          timeout: this.create(item.id, (_a = item.remaining) != null ? _a : item.duration),
          resumedAt: Date.now()
          // ...which corresponds to the item duration when the stream is resumed for the first time
        });
      });
    },
    resumeWithDebounce(ms) {
      this.debounceTimeout = window.setTimeout(() => {
        this.resume();
      }, ms);
    }
  };
}
function createPushProxies({
  config,
  items,
  queue,
  animations,
  timeouts
}) {
  return {
    destroyAll() {
      queue.clear();
      items.clear();
    },
    clearAll() {
      animations.playClearAll();
    },
    clear(id, { isDestroy = false } = {}) {
      var _a;
      const isLast = ((_a = items.entries.value[items.entries.value.length - 1]) == null ? void 0 : _a.id) === id;
      if (isLast) timeouts.resume();
      animations.playLeave(id, { isUserTriggered: true, isDestroy });
    },
    push(options) {
      const entry = mergeNotificationOptions(config.notifications.value, options);
      const createdAt = Date.now();
      if (config.avoidDuplicates.value && isStatic(entry.type)) {
        const dupe = items.findDupe(entry);
        if (dupe) {
          window.clearTimeout(dupe.timeout);
          items.update(dupe.id, {
            createdAt,
            duration: entry.duration,
            remaining: void 0,
            timeout: timeouts.create(dupe.id, entry.duration),
            duplicateCount: dupe.duplicateCount + 1
          });
          isRef(dupe.message) ? dupe.message.value += "\uFEFF" : dupe.message += "\uFEFF";
          items.triggerRef();
        }
        const queueDupe = items.findDupe.call(queue, entry);
        if (queueDupe) {
          queue.update(queueDupe.id, {
            duration: entry.duration,
            createdAt,
            duplicateCount: queueDupe.duplicateCount + 1
          });
          queue.triggerRef();
        }
        if (queueDupe || dupe) return;
      }
      const createTimeout = () => timeouts.create(entry.id, entry.duration);
      if (options.type === NotificationTypeKeys.PROMISE_RESOLVE || options.type === NotificationTypeKeys.PROMISE_REJECT) {
        if (queue.get(entry.id)) {
          queue.update(entry.id, __spreadProps(__spreadValues({}, entry), { createdAt, timeout: createTimeout }));
          queue.triggerRef();
        } else {
          items.update(entry.id, __spreadProps(__spreadValues({}, entry), { createdAt, timeout: createTimeout() }));
          items.triggerRef();
        }
      } else {
        const isQueueActive = config.enqueue.value;
        const hasReachedLimit = items.length >= config.limit.value;
        const shouldDiscard = !isQueueActive && hasReachedLimit;
        const shouldEnqueue = isQueueActive && !options.skipQueue && hasReachedLimit;
        if (shouldDiscard) {
          items.entries.value.slice(config.limit.value - 1).forEach((e) => timeouts.create(e.id, 1));
        }
        const item = __spreadProps(__spreadValues({}, entry), {
          createdAt,
          duplicateCount: 0,
          animationAttrs: {
            class: animations.isReducedMotion.value ? "" : config.animations.value.enter,
            onAnimationend() {
              if (item.animationAttrs.class === config.animations.value.enter) {
                items.update(entry.id, {
                  animationAttrs: { class: "", onAnimationend: () => {
                  } }
                });
              }
            }
          },
          timeout: shouldEnqueue ? createTimeout : createTimeout(),
          clear: () => this.clear(entry.id),
          destroy: () => this.clear(entry.id, { isDestroy: true })
        });
        if (shouldEnqueue) {
          queue.add(item);
        } else {
          items.add(item);
        }
      }
    }
  };
}
const notivueInstanceInjectionKey = Symbol();
const notivueInjectionKey = Symbol();
function createStoreWatchers(store) {
  return [
    watch(
      store.items.lifecycleEventsCount,
      () => {
        store.animations.updatePositions();
      },
      { flush: "post" }
    ),
    watch(
      store.config.position,
      () => {
        store.animations.updatePositions({ isImmediate: true });
      },
      { flush: "post" }
    ),
    watch(
      () => store.items.length === 0 && store.queue.length === 0,
      (isReset) => {
        if (isReset) {
          store.timeouts.reset();
          store.elements.setRootAttrs({});
        }
      },
      { flush: "post" }
    )
  ];
}
let startInstance = () => {
};
let stopInstance = () => {
};
function createInstance(startOnCreation) {
  const isRunning = ref(startOnCreation);
  const isRunningReadonly = readonly(isRunning);
  function setupInstance(store, push2) {
    const watchStore = () => createStoreWatchers(store);
    if (startOnCreation) setPush(push2);
    let unwatchStore = startOnCreation ? watchStore() : [() => {
    }];
    const instance = {
      isRunning: isRunningReadonly,
      startInstance() {
        if (isRunning.value) return;
        setPush(push2);
        unwatchStore = watchStore();
        isRunning.value = true;
      },
      stopInstance() {
        if (!isRunning.value) return;
        store.items.clear();
        store.queue.clear();
        store.items.clearLifecycleEvents();
        setPush(createPushMock());
        unwatchStore.forEach((unwatch) => unwatch());
        isRunning.value = false;
      }
    };
    startInstance = () => instance.startInstance();
    stopInstance = () => instance.stopInstance();
    return instance;
  }
  return { isRunning: isRunningReadonly, setupInstance };
}
function createProvides(startOnCreation, userConfig) {
  const { setupInstance, isRunning } = createInstance(startOnCreation);
  const store = createStore(userConfig, isRunning);
  const proxies = createPushProxies(store);
  const push2 = Object.freeze(createPush(proxies));
  const instance = setupInstance(store, push2);
  return {
    store,
    instance,
    push: push2
  };
}
function createNotivue$1(pluginConfig = {}) {
  return {
    install(app) {
      var _b;
      const _a = pluginConfig, { startOnCreation = true } = _a, userConfig = __objRest(_a, ["startOnCreation"]);
      const { store, instance, push: push2 } = createProvides(startOnCreation, userConfig);
      app.provide(notivueInstanceInjectionKey, instance);
      app.provide(notivueInjectionKey, store);
      (_b = app.config.globalProperties).$push || (_b.$push = push2);
    }
  };
}
function createNotivue(pluginConfig = {}) {
  return {
    install(app) {
      Object.assign(app.config.globalProperties, {
        notivuePluginConfig: pluginConfig
      });
    }
  };
}
function useStore() {
  return inject(notivueInjectionKey);
}
function useNotivueInstance() {
  if (isSSR) {
    return {
      isRunning: ref(true),
      startInstance: () => {
      },
      stopInstance: () => {
      }
    };
  }
  return inject(notivueInstanceInjectionKey);
}
function useNotivue() {
  if (isSSR) {
    return __spreadProps(__spreadValues({}, toRefs(reactive(DEFAULT_CONFIG))), {
      update: () => {
      },
      isTopAlign: computed(() => true),
      isStreamPaused: ref(false)
    });
  }
  const store = useStore();
  return __spreadProps(__spreadValues({}, store.config), {
    isStreamPaused: readonly(store.timeouts.isStreamPaused),
    isTopAlign: computed(() => store.config.position.value.indexOf("top") === 0)
  });
}
function usePush() {
  return push$1;
}
function useNotifications() {
  if (isSSR) {
    return {
      entries: computed(() => []),
      queue: computed(() => [])
    };
  }
  const store = useStore();
  return {
    entries: computed(() => store.items.entries.value.map(getSlotItem)),
    queue: computed(() => store.queue.entries.value.map(getSlotItem))
  };
}
const keyboardInjectionKey = Symbol("");
const focusableEls = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]';
const DEFAULT_PROPS$2 = {
  comboKey: "n",
  handleClicks: true,
  leaveMessage: "You're leaving the notifications stream. Press Control + N to navigate it again.",
  emptyMessage: "No notifications to navigate",
  renderAnnouncement: true,
  maxAnnouncements: 2
};
function useNotivueKeyboard() {
  if (isSSR) {
    return {
      elementsTabIndex: ref(-1),
      containersTabIndex: computed(() => ({}))
    };
  }
  return inject(keyboardInjectionKey);
}
const DEFAULT_PROPS$1 = {
  listAriaLabel: "Notifications"
};
const NotivueClientOnly = defineComponent({
  setup(_, { slots, attrs }) {
    const isMounted = ref(false);
    onMounted(() => isMounted.value = true);
    return () => {
      var _a;
      if (isMounted.value) return (_a = slots.default) == null ? void 0 : _a.call(slots);
      return createElementBlock("span", attrs, "");
    };
  }
});
const isMouse = (e) => e.pointerType === "mouse";
function getAriaLabel(item) {
  return `${item.title ? `${item.title}: ` : ""}${item.message}`;
}
const _hoisted_1$a = ["role", "aria-live"];
const _sfc_main$g = /* @__PURE__ */ defineComponent({
  __name: "AriaLive",
  props: {
    item: {}
  },
  setup(__props) {
    const props = __props;
    const visuallyHidden = {
      position: "absolute",
      width: "1px",
      height: "1px",
      padding: "0",
      margin: "-1px",
      overflow: "hidden",
      clip: "rect(0, 0, 0, 0)",
      whiteSpace: "nowrap",
      border: "0"
    };
    return (_ctx, _cache) => {
      return openBlock(), createElementBlock("div", {
        style: visuallyHidden,
        role: props.item.ariaRole,
        "aria-live": props.item.ariaLive,
        key: `${_ctx.item.id}_${_ctx.item.type}`,
        "aria-atomic": "true"
      }, toDisplayString(unref(getAriaLabel)(props.item)), 9, _hoisted_1$a);
    };
  }
});
function useMouseEvents() {
  const { timeouts, config } = useStore();
  function pauseHover(e) {
    if (isMouse(e)) timeouts.pause();
  }
  function resumeHover(e) {
    if (isMouse(e)) timeouts.resume();
  }
  return computed(
    () => config.pauseOnHover.value && !timeouts.isStreamFocused.value ? {
      onPointerenter: pauseHover,
      onPointerleave: resumeHover
    } : {}
  );
}
function useTouchEvents() {
  const { timeouts, config } = useStore();
  function pauseTouch(e) {
    if (!isMouse(e)) {
      timeouts.clearDebounceTimeout();
      timeouts.pause();
      timeouts.resumeWithDebounce(2e3);
    }
  }
  onBeforeUnmount(() => {
    timeouts.clearDebounceTimeout();
  });
  return computed(
    () => config.pauseOnTouch.value && !timeouts.isStreamFocused.value ? { onPointerdown: pauseTouch } : {}
  );
}
const boxSizing = { boxSizing: "border-box" };
const baseStyles = {
  list: __spreadProps(__spreadValues({}, boxSizing), {
    display: "flex",
    justifyContent: "center",
    listStyle: "none",
    margin: "0 auto",
    maxWidth: "var(--nv-root-width, 100%)",
    padding: "0",
    pointerEvents: "none",
    position: "fixed",
    zIndex: "var(--nv-z, 500)"
  }),
  listItem: __spreadProps(__spreadValues({}, boxSizing), {
    display: "flex",
    margin: "0",
    position: "absolute",
    transitionProperty: "transform",
    width: "100%"
  }),
  itemContainer: __spreadProps(__spreadValues({}, boxSizing), {
    maxWidth: "100%",
    padding: `0 0 var(--nv-gap, 0.75rem) 0`,
    pointerEvents: "auto"
  })
};
function useNotivueStyles() {
  const { isTopAlign, position } = useNotivue();
  const offset = computed(() => {
    const isTop = isTopAlign.value;
    const inset = [
      `var(--nv-root-top, ${isTop ? "1.25rem" : "0px"})`,
      "var(--nv-root-right, 1.25rem)",
      `var(--nv-root-bottom, ${isTop ? "0px" : "1.25rem"})`,
      "var(--nv-root-left, 1.25rem)"
    ];
    const clipPath = inset.map((v) => `calc(-1 * ${v})`);
    isTop ? clipPath.splice(2, 1, "0px") : clipPath.splice(0, 1, "0px");
    return { inset: inset.join(" "), clipPath: `inset(${clipPath.join(" ")})` };
  });
  const xAlignment = computed(() => ({
    [isTopAlign.value ? "top" : "bottom"]: "0",
    justifyContent: `var(--nv-root-x-align, ${position.value.endsWith("left") ? "flex-start" : position.value.endsWith("right") ? "flex-end" : "center"})`
  }));
  return computed(() => ({
    list: __spreadValues(__spreadValues({}, baseStyles.list), offset.value),
    listItem: __spreadValues(__spreadValues({}, baseStyles.listItem), xAlignment.value),
    itemContainer: baseStyles.itemContainer
  }));
}
function useWindowSize(onResize) {
  function _onResize() {
    if (window.matchMedia("(max-width: 1100px)").matches) {
      onResize();
    }
  }
  onMounted(() => {
    window.addEventListener("resize", _onResize);
  });
  onBeforeUnmount(() => {
    window.removeEventListener("resize", _onResize);
  });
}
function useResizeListObserver(elements, onSizeChange) {
  let resizeObserver;
  const calls = /* @__PURE__ */ new WeakSet();
  onMounted(() => {
    resizeObserver = new ResizeObserver((entries, observer) => {
      for (const e of entries) {
        if (!calls.has(e.target)) {
          calls.add(e.target);
        } else {
          if (Object.values(e.contentRect.toJSON()).every((val) => val === 0)) {
            calls.delete(e.target);
            observer.unobserve(e.target);
          } else {
            onSizeChange();
            calls.delete(e.target);
            observer.unobserve(e.target);
          }
        }
      }
    });
  });
  watch(
    elements,
    (el) => {
      if (el.length > 0) el.forEach((el2) => resizeObserver == null ? void 0 : resizeObserver.observe(el2));
    },
    { flush: "post" }
  );
  onBeforeUnmount(() => {
    resizeObserver == null ? void 0 : resizeObserver.disconnect();
  });
}
function useSizes() {
  const { elements, animations } = useStore();
  useWindowSize(() => animations.updatePositions({ isImmediate: true }));
  useResizeListObserver(elements.items.value, () => animations.updatePositions());
}
function useWindowFocus() {
  const { config, timeouts } = useStore();
  function onFocus() {
    if (timeouts.isStreamFocused.value) return;
    if (config.pauseOnTabChange.value) timeouts.resume();
  }
  function onBlur() {
    if (timeouts.isStreamFocused.value) return;
    if (config.pauseOnTabChange.value) timeouts.pause();
  }
  onMounted(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
  });
  onBeforeUnmount(() => {
    window.removeEventListener("focus", onFocus);
    window.removeEventListener("blur", onBlur);
  });
}
function useReducedMotion() {
  const { animations } = useStore();
  const query = window.matchMedia("(prefers-reduced-motion: reduce)");
  const onMatch = () => animations.setReducedMotion(query.matches);
  onMounted(() => {
    var _a;
    onMatch();
    (_a = query.addEventListener) == null ? void 0 : _a.call(query, "change", onMatch);
  });
  onBeforeUnmount(() => {
    var _a;
    (_a = query.removeEventListener) == null ? void 0 : _a.call(query, "change", onMatch);
  });
}
const _hoisted_1$9 = ["data-notivue-align", "aria-label"];
const _hoisted_2$9 = ["data-notivue-item", "aria-setsize", "aria-posinset"];
const _hoisted_3$6 = ["aria-label", "tabindex", "data-notivue-container"];
const _sfc_main$f = /* @__PURE__ */ defineComponent({
  __name: "NotivueImpl",
  props: /* @__PURE__ */ mergeDefaults({
    class: {},
    containersTabIndex: {},
    listAriaLabel: {},
    styles: {}
  }, DEFAULT_PROPS$1),
  setup(__props) {
    const props = __props;
    const { config, items, elements } = useStore();
    const styles = useNotivueStyles();
    const mouseEvents = useMouseEvents();
    const touchEvents = useTouchEvents();
    useReducedMotion();
    useWindowFocus();
    useSizes();
    return (_ctx, _cache) => {
      var _a;
      return openBlock(), createBlock(Teleport, {
        to: unref(config).teleportTo.value === false ? void 0 : unref(config).teleportTo.value,
        disabled: unref(config).teleportTo.value === false
      }, [
        unref(items).entries.value.length > 0 ? (openBlock(), createElementBlock("ol", mergeProps({ key: 0 }, __spreadValues(__spreadValues(__spreadValues({}, unref(mouseEvents)), unref(touchEvents)), unref(elements).rootAttrs.value), {
          "data-notivue-align": unref(config).position.value.split("-")[0],
          "aria-label": props.listAriaLabel,
          ref: unref(elements).root,
          class: props.class,
          style: __spreadValues(__spreadValues({}, unref(styles).list), (_a = props.styles) == null ? void 0 : _a.list)
        }), [
          (openBlock(true), createElementBlock(Fragment, null, renderList(unref(items).entries.value, (item, i) => {
            var _a2, _b, _c, _d;
            return openBlock(), createElementBlock("li", {
              tabindex: "-1",
              key: item.id,
              "data-notivue-item": item.id,
              "aria-setsize": unref(items).length,
              "aria-posinset": i + 1,
              ref_for: true,
              ref: unref(elements).items,
              style: normalizeStyle(__spreadValues(__spreadValues(__spreadValues({}, unref(styles).listItem), item.positionStyles), (_a2 = props.styles) == null ? void 0 : _a2.listItem))
            }, [
              item.ariaLiveOnly ? (openBlock(), createBlock(_sfc_main$g, {
                key: 0,
                item
              }, null, 8, ["item"])) : (openBlock(), createElementBlock("div", mergeProps({
                key: 1,
                ref_for: true
              }, item.animationAttrs, {
                "aria-label": unref(getAriaLabel)(item),
                tabindex: (_c = (_b = _ctx.containersTabIndex) == null ? void 0 : _b[item.id]) != null ? _c : -1,
                "data-notivue-container": item.id,
                ref_for: true,
                ref: unref(elements).containers,
                style: __spreadValues(__spreadValues({}, unref(styles).itemContainer), (_d = props.styles) == null ? void 0 : _d.itemContainer)
              }), [
                renderSlot(_ctx.$slots, "default", mergeProps({ ref_for: true }, unref(getSlotItem)(item)))
              ], 16, _hoisted_3$6))
            ], 12, _hoisted_2$9);
          }), 128))
        ], 16, _hoisted_1$9)) : createCommentVNode("", true)
      ], 8, ["to", "disabled"]);
    };
  }
});
const _sfc_main$e = /* @__PURE__ */ defineComponent({
  __name: "Notivue",
  props: /* @__PURE__ */ mergeDefaults({
    class: {},
    containersTabIndex: {},
    listAriaLabel: {},
    styles: {}
  }, DEFAULT_PROPS$1),
  setup(__props) {
    const props = __props;
    const { isRunning } = useNotivueInstance();
    return (_ctx, _cache) => {
      return openBlock(), createBlock(unref(NotivueClientOnly), null, {
        default: withCtx(() => [
          unref(isRunning) ? (openBlock(), createBlock(_sfc_main$f, normalizeProps(mergeProps({ key: 0 }, props)), {
            default: withCtx((item) => [
              renderSlot(_ctx.$slots, "default", normalizeProps(guardReactiveProps(item)))
            ]),
            _: 3
          }, 16)) : createCommentVNode("", true)
        ]),
        _: 3
      });
    };
  }
});
const _sfc_main$d = /* @__PURE__ */ defineComponent({
  __name: "Notivue",
  props: /* @__PURE__ */ mergeDefaults({
    class: {},
    containersTabIndex: {},
    listAriaLabel: {},
    styles: {}
  }, DEFAULT_PROPS$1),
  setup(__props) {
    var _a;
    const props = __props;
    const pluginConfig = (_a = getCurrentInstance()) == null ? void 0 : _a.appContext.config.globalProperties.notivuePluginConfig;
    const _b = pluginConfig || {}, { startOnCreation = true } = _b, userConfig = __objRest(_b, ["startOnCreation"]);
    const { store, instance, push: push2 } = createProvides(startOnCreation, userConfig);
    provide(notivueInstanceInjectionKey, instance);
    provide(notivueInjectionKey, store);
    function onPush(e) {
      const notification = push2[e.detail.type](e.detail);
      window.dispatchEvent(
        new CustomEvent(e.detail.resultEventName, {
          detail: notification
        })
      );
    }
    const onClearAll = () => push2.clearAll();
    const onDestroyAll = () => push2.destroyAll();
    const events = [
      ["notivue:push", onPush],
      ["notivue:clear-all", onClearAll],
      ["notivue:destroy-all", onDestroyAll]
    ];
    onMounted(() => {
      events.forEach(([e, handler]) => window.addEventListener(e, handler));
    });
    onBeforeUnmount(() => {
      events.forEach(([e, handler]) => window.removeEventListener(e, handler));
    });
    return (_ctx, _cache) => {
      return openBlock(), createBlock(unref(NotivueClientOnly), null, {
        default: withCtx(() => [
          createVNode(_sfc_main$f, normalizeProps(guardReactiveProps(props)), {
            default: withCtx((item) => [
              renderSlot(_ctx.$slots, "default", normalizeProps(guardReactiveProps(item)))
            ]),
            _: 3
          }, 16)
        ]),
        _: 3
      });
    };
  }
});
const DEFAULT_PROPS = {
  touchOnly: false,
  exclude: "a, button",
  disabled: false,
  threshold: 0.5
};
const RETURN_DUR = 300;
const DEBOUNCE = {
  Mouse: 200,
  Touch: 1e3,
  TouchExternal: 1400
};
const _sfc_main$c = /* @__PURE__ */ defineComponent({
  __name: "NotivueSwipe",
  props: /* @__PURE__ */ mergeDefaults({
    item: {},
    touchOnly: { type: Boolean },
    exclude: {},
    disabled: { type: Boolean },
    threshold: {},
    destroy: { type: Boolean }
  }, DEFAULT_PROPS),
  setup(__props) {
    const { items, timeouts, elements, animations } = useStore();
    const props = __props;
    const touchOnly = toRef(props, "touchOnly");
    const exclude = toRef(props, "exclude");
    const isDisabledByUser = toRef(props, "disabled");
    const threshold = toRef(props, "threshold");
    const isPromise = computed(() => props.item.type === NotificationTypeKeys.PROMISE);
    const isEnabled = computed(
      () => !timeouts.isStreamFocused.value && !isDisabledByUser.value && !isPromise.value && props.item.duration < Infinity
    );
    const itemRef = ref(null);
    const initialState = {
      isPressed: false,
      isClearing: false,
      startX: 0,
      currentX: 0
    };
    const state = shallowReactive(initialState);
    const styles = shallowRef({});
    function setState(properties) {
      Object.assign(state, properties);
    }
    function setStyles(properties) {
      styles.value = __spreadValues(__spreadValues({}, styles.value), properties);
    }
    function resetStyles() {
      styles.value = {};
    }
    watch(
      () => items.length,
      (newLen, prevLen) => {
        if (newLen !== prevLen && state.isPressed) {
          setReturnStyles();
          setState(initialState);
        }
      },
      { flush: "post" }
    );
    function setDragStyles() {
      if (!itemRef.value) return;
      setStyles({
        touchAction: "none",
        userSelect: "none",
        cursor: "grab"
      });
      itemRef.value.querySelectorAll("*").forEach((el) => {
        if (el instanceof HTMLElement) {
          el.style.userSelect = "none";
          el.style.touchAction = "none";
        }
      });
    }
    function resetDragStyles() {
      if (!itemRef.value) return;
      resetStyles();
      itemRef.value.querySelectorAll("*").forEach((el) => {
        if (el instanceof HTMLElement) {
          el.style.userSelect = "auto";
          el.style.touchAction = "auto";
        }
      });
    }
    function shouldSwipe(e) {
      return touchOnly.value && e.pointerType !== "touch" ? false : true;
    }
    function setReturnStyles() {
      setStyles({
        transition: animations.isReducedMotion.value ? "none" : `${RETURN_DUR}ms cubic-bezier(0.76, 0, 0.24, 1)`,
        transform: `translate3d(0px, 0px, 0px)`,
        opacity: "1"
      });
    }
    function isPointerInside(e) {
      if (!elements.root.value || !itemRef.value) return false;
      const { clientWidth, offsetLeft } = itemRef.value;
      const left = offsetLeft + elements.root.value.offsetLeft;
      const right = left + clientWidth;
      return e.clientX > left && e.clientX < right;
    }
    function getDebounceMs(e) {
      return isMouse(e) ? DEBOUNCE.Mouse : DEBOUNCE.Touch;
    }
    function pauseTimeouts() {
      timeouts.clearDebounceTimeout();
      timeouts.pause();
    }
    function resumeTimeouts(ms) {
      timeouts.clearDebounceTimeout();
      timeouts.resumeWithDebounce(ms);
    }
    function onPointerEnter(e) {
      if (!shouldSwipe(e)) resetDragStyles();
    }
    function onPointerDown(e) {
      if (!shouldSwipe(e)) return;
      e.stopPropagation();
      if (!itemRef.value) return;
      if (e.button !== 0) return;
      if (state.isPressed || state.isClearing) return;
      if (exclude.value) {
        const excludedEls = Array.from(itemRef.value.querySelectorAll(exclude.value));
        if (excludedEls.includes(e.target)) {
          if (!isMouse(e)) {
            pauseTimeouts();
            resumeTimeouts(DEBOUNCE.TouchExternal);
          }
          return;
        }
      }
      if (!isMouse(e)) pauseTimeouts();
      setState({
        startX: e.clientX,
        isPressed: true
      });
    }
    function onPointerMove(e) {
      if (!shouldSwipe(e)) return;
      e.stopPropagation();
      if (!state.isPressed || state.isClearing) return;
      const { clientWidth } = itemRef.value;
      setStyles({
        transition: "none",
        transform: `translate3d(${state.currentX}px, 0px, 0px)`,
        opacity: `${1 - Math.abs(state.currentX) / clientWidth * (1 / threshold.value)}`
      });
      setState({
        currentX: e.clientX - state.startX
      });
      if (Math.abs(state.currentX) > clientWidth * threshold.value) {
        state.isClearing = true;
        onPointerMoveClear(e);
      }
    }
    function onPointerMoveClear(e) {
      props.item.destroy();
      if (isMouse(e) && isPointerInside(e)) {
        const sortedContainers = elements.getSortedItems();
        const isLastItem = sortedContainers[sortedContainers.length - 1].contains(itemRef.value);
        if (!isLastItem) pauseTimeouts();
      } else {
        resumeTimeouts(getDebounceMs(e));
      }
      setState({
        isClearing: false
      });
    }
    function onPointerUp(e) {
      if (!shouldSwipe(e)) return;
      e.stopPropagation();
      if (!state.isPressed || state.isClearing) return;
      if (isMouse(e) && isPointerInside(e)) {
        pauseTimeouts();
      } else {
        resumeTimeouts(getDebounceMs(e));
      }
      setReturnStyles();
      setState({
        startX: 0,
        currentX: 0,
        isPressed: false
      });
    }
    function onPointerLeave(e) {
      if (!shouldSwipe(e)) return;
      e.stopPropagation();
      if (!state.isPressed || state.isClearing) return;
      setReturnStyles();
      setState({
        startX: 0,
        currentX: 0,
        isPressed: false
      });
      resumeTimeouts(getDebounceMs(e));
    }
    const events = [
      ["pointerenter", onPointerEnter],
      ["pointerdown", onPointerDown],
      ["pointermove", onPointerMove],
      ["pointerup", onPointerUp],
      ["pointerleave", onPointerLeave]
    ];
    function addListeners() {
      if (!itemRef.value) return;
      events.forEach(([event, handler]) => {
        itemRef.value.addEventListener(event, handler);
      });
    }
    function removeListeners() {
      if (!itemRef.value) return;
      events.forEach(([event, handler]) => {
        itemRef.value.removeEventListener(event, handler);
      });
    }
    watch(
      isEnabled,
      (_isEnabled, _, onCleanup) => {
        nextTick(() => {
          if (_isEnabled) {
            setDragStyles();
            nextTick(addListeners);
          }
        });
        onCleanup(() => {
          removeListeners();
          resetDragStyles();
        });
      },
      { immediate: true, flush: "post" }
    );
    onBeforeUnmount(removeListeners);
    return (_ctx, _cache) => {
      return !isDisabledByUser.value ? (openBlock(), createElementBlock("div", {
        key: 0,
        ref_key: "itemRef",
        ref: itemRef,
        style: normalizeStyle(styles.value)
      }, [
        renderSlot(_ctx.$slots, "default")
      ], 4)) : renderSlot(_ctx.$slots, "default", { key: 1 });
    };
  }
});
function useKeyboardFocus() {
  const isKeyboardFocus = ref(false);
  const setKeyboardFocus = () => isKeyboardFocus.value = true;
  const unsetKeyboardFocus = () => isKeyboardFocus.value = false;
  const events = [
    ["keydown", setKeyboardFocus],
    ["mousedown", unsetKeyboardFocus],
    ["touchstart", unsetKeyboardFocus]
  ];
  onMounted(() => {
    events.forEach(([e, handler]) => document.addEventListener(e, handler));
  });
  onBeforeUnmount(() => {
    events.forEach(([e, handler]) => document.removeEventListener(e, handler));
  });
  return { isKeyboardFocus };
}
function useLastFocused() {
  const { root: stream } = useStore().elements;
  const lastFocused = ref(null);
  function onFocusCapture(e) {
    var _a;
    const isValidTarget = e.target instanceof HTMLElement;
    if (isValidTarget && ((_a = stream.value) == null ? void 0 : _a.contains(e.target))) return;
    if (isValidTarget) lastFocused.value = e.target;
  }
  function focusLastElement() {
    if (lastFocused.value) {
      lastFocused.value.focus();
    } else {
      document.activeElement instanceof HTMLElement && document.activeElement.blur();
      document.body.focus();
    }
  }
  onMounted(() => {
    document.addEventListener("focus", onFocusCapture, true);
  });
  onBeforeUnmount(() => {
    document.removeEventListener("focus", onFocusCapture, true);
  });
  return { focusLastElement };
}
const _sfc_main$b = /* @__PURE__ */ defineComponent({
  __name: "NotivueKeyboardImpl",
  props: /* @__PURE__ */ mergeDefaults({
    comboKey: {},
    handleClicks: { type: Boolean },
    leaveMessage: {},
    emptyMessage: {},
    renderAnnouncement: { type: Boolean },
    maxAnnouncements: {}
  }, DEFAULT_PROPS$2),
  setup(__props) {
    const props = __props;
    const { comboKey, handleClicks, leaveMessage, emptyMessage, renderAnnouncement, maxAnnouncements } = toRefs(props);
    const sharedOptions = {
      ariaRole: "alert",
      ariaLive: "assertive",
      skipQueue: true,
      ariaLiveOnly: !renderAnnouncement.value,
      props: {
        isNotivueKeyboard: true
      }
    };
    const leavePushOptions = computed(() => __spreadValues({
      message: leaveMessage.value
    }, sharedOptions));
    const emptyPushOptions = computed(() => __spreadValues({
      message: emptyMessage.value
    }, sharedOptions));
    const { elements, timeouts, queue } = useStore();
    const config = useNotivue();
    const { focusLastElement } = useLastFocused();
    const { isKeyboardFocus } = useKeyboardFocus();
    const candidateIds = ref({ qualified: [], unqualified: [] });
    const candidateContainers = ref([]);
    const unqualifiedContainers = ref([]);
    const elementsTabIndex = ref(-1);
    function setTabIndex(value) {
      elementsTabIndex.value = value;
    }
    let announcementsCount = 0;
    let hasNeverTabbedStream = true;
    let allInnerFocusableEls = [];
    const containersTabIndex = computed(() => {
      const map = {};
      candidateIds.value.qualified.forEach((id) => map[id] = elementsTabIndex.value);
      candidateIds.value.unqualified.forEach((id) => map[id] = -1);
      return map;
    });
    function onStreamEnter() {
      if (candidateContainers.value.length === 0) return;
      setTabIndex(0);
      timeouts.setStreamFocus();
      timeouts.pause();
      nextTick(() => {
        candidateContainers.value[0].focus();
      });
    }
    function onStreamLeave({ announce = true } = {}) {
      focusLastElement();
      setTabIndex(-1);
      timeouts.setStreamFocus(false);
      timeouts.resume();
      if (announce && announcementsCount < maxAnnouncements.value) {
        announcementsCount++;
        push$1.info(leavePushOptions.value);
      }
    }
    provide(keyboardInjectionKey, {
      containersTabIndex,
      elementsTabIndex: readonly(elementsTabIndex)
    });
    watch(elements.containers, setCandidates, { deep: true });
    function setCandidates(newContainers) {
      const _ids = { qualified: [], unqualified: [] };
      let _candidateContainers = [];
      let _unqualifiedContainers = [];
      let _focusableEls = [];
      newContainers.map((container) => ({ id: container.dataset.notivueContainer, container })).sort((a, b) => +b.id - +a.id).forEach(({ id, container }) => {
        const innerFocusableEls = Array.from(container.querySelectorAll(focusableEls)).filter(
          (el) => el instanceof HTMLElement
        );
        _focusableEls.push(...innerFocusableEls);
        if (innerFocusableEls.length > 1) {
          _ids.qualified.push(id);
          _candidateContainers.push(container);
        } else {
          _ids.unqualified.push(id);
          _unqualifiedContainers.push(container);
        }
      });
      candidateIds.value = _ids;
      candidateContainers.value = _candidateContainers;
      unqualifiedContainers.value = _unqualifiedContainers;
      allInnerFocusableEls = _focusableEls;
    }
    watch(
      candidateContainers,
      (currCandidates, prevCandidates, onCleanup) => {
        if (currCandidates.length === 0) return;
        const hasCandidates = currCandidates.length > 0;
        const isNewCandidate = currCandidates.some((container) => {
          return !prevCandidates.some((prevContainer) => prevContainer === container);
        });
        const isAlreadyNavigating = isNewCandidate && timeouts.isStreamFocused.value;
        const shouldAddEnterListener = isNewCandidate && !timeouts.isStreamFocused.value || hasCandidates && hasNeverTabbedStream;
        if (isAlreadyNavigating) {
          currCandidates[0].focus();
        } else if (shouldAddEnterListener) {
          addEnterListener();
        }
        onCleanup(() => {
          if (shouldAddEnterListener) removeEnterListener();
        });
      },
      { flush: "post" }
    );
    function onAllowedStreamNavigation(e) {
      if (!e.shiftKey && e.key === "Tab" && candidateContainers.value.length > 0) {
        e.preventDefault();
        if (hasNeverTabbedStream) hasNeverTabbedStream = false;
        onStreamEnter();
        nextTick(removeEnterListener);
      }
    }
    function addEnterListener() {
      removeEnterListener();
      document.addEventListener("keydown", onAllowedStreamNavigation);
    }
    function removeEnterListener() {
      document.removeEventListener("keydown", onAllowedStreamNavigation);
    }
    watch(
      unqualifiedContainers,
      (newUnqualified) => {
        if (!config.enqueue.value || !timeouts.isStreamFocused.value) return;
        if (newUnqualified.length > 0) {
          if (candidateContainers.value.length > 0) {
            candidateContainers.value[0].focus();
          } else {
            onStreamLeave({ announce: false });
          }
        }
      },
      { flush: "post" }
    );
    let isManualLeave = false;
    watch(
      elements.root,
      (stream, _, onCleanup) => {
        function onStreamFocusOut(e) {
          e.preventDefault();
          e.stopPropagation();
          if (!timeouts.isStreamFocused.value) return;
          if (!isKeyboardFocus.value) return;
          if (isManualLeave) return isManualLeave = false;
          if (!(stream == null ? void 0 : stream.contains(e.relatedTarget))) {
            onStreamLeave();
          }
        }
        if (stream) stream.addEventListener("focusout", onStreamFocusOut);
        onCleanup(() => {
          if (stream) stream.removeEventListener("focusout", onStreamFocusOut);
        });
      },
      { immediate: true, flush: "post" }
    );
    function onCandidatesKeydown(e) {
      let currCandidateIndex = 0;
      const isNavigatingCandidates = candidateContainers.value.some((container, index) => {
        if (container.contains(e.target) || container === e.target) {
          currCandidateIndex = index;
          return true;
        }
      });
      if (isNavigatingCandidates) {
        if (e.key === "Escape") {
          e.preventDefault();
          isManualLeave = true;
          return onStreamLeave();
        }
        if (!handleClicks.value) return;
        const isClickable = e.target instanceof HTMLButtonElement || e.target instanceof HTMLAnchorElement;
        if (isClickable && (e.key === " " || e.key === " " || e.key === "Enter")) {
          e.preventDefault();
          isManualLeave = true;
          e.target.click();
          if (queue.length > 0) return;
          const nextContainer = candidateContainers.value[currCandidateIndex + 1];
          if (nextContainer) {
            nextContainer.focus();
          } else {
            onStreamLeave();
          }
        }
      }
    }
    function onComboKeyDown(e) {
      if (e.ctrlKey && (e.key === comboKey.value.toLowerCase() || e.key === comboKey.value.toUpperCase())) {
        e.preventDefault();
        if (timeouts.isStreamFocused.value) {
          isManualLeave = true;
          return onStreamLeave();
        } else {
          if (candidateContainers.value.length > 0) {
            onStreamEnter();
          } else {
            push$1.info(emptyPushOptions.value);
          }
        }
      }
    }
    function onActionsMouseClick(e) {
      var _a;
      if (timeouts.isStreamFocused.value && !isKeyboardFocus.value) {
        if (!((_a = elements.root.value) == null ? void 0 : _a.contains(e.target))) {
          onStreamLeave();
        }
        if (allInnerFocusableEls.includes(e.target)) {
          onStreamLeave();
        }
      }
    }
    const events = [
      ["click", onActionsMouseClick],
      ["keydown", onCandidatesKeydown],
      ["keydown", onComboKeyDown]
    ];
    onMounted(() => {
      events.forEach(([event, handler]) => document.addEventListener(event, handler));
    });
    onBeforeUnmount(() => {
      events.forEach(([event, handler]) => {
        document.removeEventListener(event, handler);
      });
    });
    return (_ctx, _cache) => {
      return renderSlot(_ctx.$slots, "default", normalizeProps(guardReactiveProps({ containersTabIndex: containersTabIndex.value, elementsTabIndex: elementsTabIndex.value })));
    };
  }
});
const _sfc_main$a = /* @__PURE__ */ defineComponent({
  __name: "NotivueKeyboard",
  props: /* @__PURE__ */ mergeDefaults({
    comboKey: {},
    handleClicks: { type: Boolean },
    leaveMessage: {},
    emptyMessage: {},
    renderAnnouncement: { type: Boolean },
    maxAnnouncements: {}
  }, DEFAULT_PROPS$2),
  setup(__props) {
    const props = __props;
    const { isRunning } = useNotivueInstance();
    return (_ctx, _cache) => {
      return openBlock(), createBlock(unref(NotivueClientOnly), null, {
        default: withCtx(() => [
          unref(isRunning) ? (openBlock(), createBlock(_sfc_main$b, normalizeProps(mergeProps({ key: 0 }, props)), {
            default: withCtx(({ containersTabIndex, elementsTabIndex }) => [
              renderSlot(_ctx.$slots, "default", normalizeProps(guardReactiveProps({ containersTabIndex, elementsTabIndex })))
            ]),
            _: 3
          }, 16)) : createCommentVNode("", true)
        ]),
        _: 3
      });
    };
  }
});
const _hoisted_1$8 = /* @__PURE__ */ createElementVNode("path", { d: "M6,-0c-3.308,-0 -6,2.692 -6,6c-0,3.308 2.692,6 6,6c3.308,-0 6,-2.692 6,-6c-0,-3.308 -2.692,-6 -6,-6Zm3.123,3.989l-3.877,4.616c-0.086,0.102 -0.213,0.162 -0.346,0.164l-0.008,0c-0.131,0 -0.256,-0.055 -0.343,-0.153l-1.662,-1.846c-0.081,-0.085 -0.126,-0.199 -0.126,-0.316c0,-0.254 0.209,-0.462 0.462,-0.462c0.135,0 0.263,0.059 0.35,0.161l1.307,1.451l3.536,-4.209c0.087,-0.101 0.215,-0.159 0.349,-0.159c0.253,-0 0.461,0.208 0.461,0.461c0,0.107 -0.036,0.21 -0.103,0.292Z" }, null, -1);
const _hoisted_2$8 = [
  _hoisted_1$8
];
const _sfc_main$9 = /* @__PURE__ */ defineComponent({
  __name: "SuccessIcon",
  setup(__props) {
    return (_ctx, _cache) => {
      return openBlock(), createElementBlock("svg", normalizeProps(guardReactiveProps(unref(ionProps))), _hoisted_2$8, 16);
    };
  }
});
const _hoisted_1$7 = /* @__PURE__ */ createElementVNode("path", { d: "M6,-0c-3.308,-0 -6,2.692 -6,6c-0,3.308 2.692,6 6,6c3.308,-0 6,-2.692 6,-6c-0,-3.308 -2.692,-6 -6,-6Zm-0,9.228c-0.316,0 -0.577,-0.26 -0.577,-0.577c0,-0.316 0.261,-0.577 0.577,-0.577c0.316,0 0.577,0.261 0.577,0.577c-0,0.317 -0.261,0.577 -0.577,0.577Zm0.627,-5.802l-0.166,3.519c-0,0.253 -0.208,0.462 -0.462,0.462c-0.253,-0 -0.461,-0.209 -0.461,-0.462l-0.166,-3.518l0,-0.001c-0,-0.009 -0,-0.018 -0,-0.027c-0,-0.344 0.283,-0.627 0.627,-0.627c0.344,0 0.627,0.283 0.627,0.627c-0,0.009 -0,0.018 -0.001,0.027l0.002,-0Z" }, null, -1);
const _hoisted_2$7 = [
  _hoisted_1$7
];
const _sfc_main$8 = /* @__PURE__ */ defineComponent({
  __name: "ErrorIcon",
  setup(__props) {
    return (_ctx, _cache) => {
      return openBlock(), createElementBlock("svg", normalizeProps(guardReactiveProps(unref(ionProps))), _hoisted_2$7, 16);
    };
  }
});
const _hoisted_1$6 = /* @__PURE__ */ createElementVNode("path", { d: "M6,0c-3.308,0 -6,2.692 -6,6c0,3.308 2.692,6 6,6c3.308,0 6,-2.692 6,-6c0,-3.308 -2.692,-6 -6,-6Zm0,2.46c0.428,0 0.78,0.352 0.78,0.78c-0,0.428 -0.352,0.78 -0.78,0.78c-0.428,0 -0.78,-0.352 -0.78,-0.78c0,-0.428 0.352,-0.78 0.78,-0.78Zm1.44,6.78l-2.64,0c-0.263,0 -0.48,-0.217 -0.48,-0.48c0,-0.263 0.217,-0.48 0.48,-0.48l0.84,0l0,-2.64l-0.48,0c-0.263,0 -0.48,-0.217 -0.48,-0.48c0,-0.263 0.217,-0.48 0.48,-0.48l0.96,0c0.263,0 0.48,0.217 0.48,0.48l0,3.12l0.84,0c0.263,0 0.48,0.217 0.48,0.48c0,0.263 -0.217,0.48 -0.48,0.48Z" }, null, -1);
const _hoisted_2$6 = [
  _hoisted_1$6
];
const _sfc_main$7 = /* @__PURE__ */ defineComponent({
  __name: "InfoIcon",
  setup(__props) {
    return (_ctx, _cache) => {
      return openBlock(), createElementBlock("svg", normalizeProps(guardReactiveProps(unref(ionProps))), _hoisted_2$6, 16);
    };
  }
});
const _hoisted_1$5 = /* @__PURE__ */ createElementVNode("path", { d: "M11.963,6.037c-0,3.292 -2.671,5.963 -5.963,5.963c-3.292,0 -5.963,-2.671 -5.963,-5.963c0,-3.292 2.671,-5.962 5.963,-5.962c3.292,-0 5.963,2.67 5.963,5.962Zm-0.918,0c0,-2.785 -2.26,-5.045 -5.045,-5.045c-2.785,0 -5.045,2.26 -5.045,5.045c-0,2.786 2.26,5.046 5.045,5.046c2.785,-0 5.045,-2.26 5.045,-5.046Z" }, null, -1);
const _hoisted_2$5 = /* @__PURE__ */ createElementVNode("path", { d: "M8.401,3.449c0.163,-0.194 0.452,-0.219 0.646,-0.056c0.194,0.163 0.219,0.452 0.056,0.646l-3.853,4.587c-0.085,0.101 -0.21,0.161 -0.343,0.163c-0.133,0.003 -0.26,-0.053 -0.349,-0.151l-1.651,-1.835c-0.169,-0.188 -0.154,-0.479 0.034,-0.648c0.188,-0.169 0.479,-0.154 0.648,0.034l1.298,1.443l3.514,-4.183Z" }, null, -1);
const _hoisted_3$5 = [
  _hoisted_1$5,
  _hoisted_2$5
];
const _sfc_main$6 = /* @__PURE__ */ defineComponent({
  __name: "SuccessOutlineIcon",
  setup(__props) {
    return (_ctx, _cache) => {
      return openBlock(), createElementBlock("svg", normalizeProps(guardReactiveProps(unref(ionProps))), _hoisted_3$5, 16);
    };
  }
});
const _hoisted_1$4 = /* @__PURE__ */ createElementVNode("path", { d: "M12, 6c0, 3.313 -2.687, 6 -6, 6c-3.313,-0 -6,-2.688 -6,-6c0,-3.313 2.688,-6 6,-6c3.313,-0 6,2.687 6,6Zm-0.923,-0c-0,-2.803 -2.274,-5.077 -5.077,-5.077c-2.803,0 -5.077,2.274 -5.077,5.077c0,2.803 2.274,5.077 5.077,5.077c2.803,-0 5.077,-2.274 5.077,-5.077Z" }, null, -1);
const _hoisted_2$4 = /* @__PURE__ */ createElementVNode("path", { d: "M5.373,3.426c-0,-0.009 -0,-0.019 -0,-0.028c-0,-0.342 0.279,-0.624 0.621,-0.627c0.002,-0 0.004,-0 0.006,-0c0.344,-0 0.627,0.283 0.627,0.627c-0,0.009 -0,0.019 -0.001,0.028l0,0.001l-0.165,3.518c-0.012,0.246 -0.215,0.44 -0.461,0.44c-0.246,-0 -0.449,-0.194 -0.461,-0.44l-0.166,-3.518l0,-0.001Z" }, null, -1);
const _hoisted_3$4 = /* @__PURE__ */ createElementVNode("path", { d: "M6,9.228c-0.316,0 -0.577,-0.26 -0.577,-0.577c0,-0.316 0.261,-0.577 0.577,-0.577c0.316,0 0.577,0.261 0.577,0.577c-0,0.317 -0.261,0.577 -0.577,0.577Z" }, null, -1);
const _hoisted_4$2 = [
  _hoisted_1$4,
  _hoisted_2$4,
  _hoisted_3$4
];
const _sfc_main$5 = /* @__PURE__ */ defineComponent({
  __name: "ErrorOutlineIcon",
  setup(__props) {
    return (_ctx, _cache) => {
      return openBlock(), createElementBlock("svg", normalizeProps(guardReactiveProps(unref(ionProps))), _hoisted_4$2, 16);
    };
  }
});
const _hoisted_1$3 = /* @__PURE__ */ createElementVNode("path", { d: "M6, 0c3.313, 0 6, 2.687 6, 6c-0, 3.313 -2.687, 6 -6, 6c-3.313,-0 -6,-2.687 -6,-6c0,-3.313 2.687,-6 6,-6Zm0, 0.96c-2.783, 0 -5.04, 2.257 -5.04, 5.04c0, 2.783 2.257, 5.04 5.04, 5.04c2.783, 0 5.04,-2.257 5.04,-5.04c-0,-2.783 -2.257,-5.04 -5.04,-5.04Z" }, null, -1);
const _hoisted_2$3 = /* @__PURE__ */ createElementVNode("path", { d: "M6.6, 8.28l0.84, 0c0.265, 0 0.48, 0.215 0.48, 0.48c-0, 0.265 -0.215, 0.48 -0.48, 0.48l-2.64,-0c-0.265, 0 -0.48,-0.215 -0.48,-0.48c-0,-0.265 0.215,-0.48 0.48,-0.48l0.84, 0l-0,-2.64l-0.48, 0c-0.265, 0 -0.48,-0.215 -0.48,-0.48c-0,-0.265 0.215,-0.48 0.48,-0.48l0.96, 0c0.265, 0 0.48, 0.215 0.48, 0.48l-0, 3.12Z" }, null, -1);
const _hoisted_3$3 = /* @__PURE__ */ createElementVNode("path", { d: "M6, 2.46c-0.428, 0 -0.78, 0.352 -0.78, 0.78c-0, 0.428 0.352, 0.78 0.78, 0.78c0.428, 0 0.78,-0.352 0.78,-0.78c-0,-0.428 -0.352,-0.78 -0.78,-0.78Z" }, null, -1);
const _hoisted_4$1 = [
  _hoisted_1$3,
  _hoisted_2$3,
  _hoisted_3$3
];
const _sfc_main$4 = /* @__PURE__ */ defineComponent({
  __name: "InfoOutlineIcon",
  setup(__props) {
    return (_ctx, _cache) => {
      return openBlock(), createElementBlock("svg", normalizeProps(guardReactiveProps(unref(ionProps))), _hoisted_4$1, 16);
    };
  }
});
const _hoisted_1$2 = /* @__PURE__ */ createElementVNode("path", {
  d: "M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z",
  opacity: ".25"
}, null, -1);
const _hoisted_2$2 = /* @__PURE__ */ createElementVNode("path", {
  d: "M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z",
  class: "Notivue__spinner"
}, null, -1);
const _hoisted_3$2 = [
  _hoisted_1$2,
  _hoisted_2$2
];
const _sfc_main$3 = /* @__PURE__ */ defineComponent({
  __name: "PromiseIcon",
  setup(__props) {
    return (_ctx, _cache) => {
      return openBlock(), createElementBlock("svg", normalizeProps(guardReactiveProps(__spreadProps(__spreadValues({}, unref(svgProps)), { width: 28, height: 28, fill: "currentColor" }))), _hoisted_3$2, 16);
    };
  }
});
const _hoisted_1$1 = /* @__PURE__ */ createElementVNode("line", {
  x1: "18",
  y1: "6",
  x2: "6",
  y2: "18"
}, null, -1);
const _hoisted_2$1 = /* @__PURE__ */ createElementVNode("line", {
  x1: "6",
  y1: "6",
  x2: "18",
  y2: "18"
}, null, -1);
const _hoisted_3$1 = [
  _hoisted_1$1,
  _hoisted_2$1
];
const _sfc_main$2 = /* @__PURE__ */ defineComponent({
  __name: "CloseIcon",
  setup(__props) {
    return (_ctx, _cache) => {
      return openBlock(), createElementBlock("svg", normalizeProps(guardReactiveProps(unref(featherProps))), _hoisted_3$1, 16);
    };
  }
});
const svgProps = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  "aria-hidden": "true"
};
const ionProps = __spreadProps(__spreadValues({}, svgProps), {
  fill: "currentColor",
  viewBox: "0 0 12 12"
});
const featherProps = __spreadProps(__spreadValues({}, svgProps), {
  stroke: "currentColor",
  "stroke-width": 2,
  "stroke-linecap": "round",
  "stroke-linejoin": "round"
});
const filledIcons = {
  [NotificationTypeKeys.SUCCESS]: markRaw(_sfc_main$9),
  [NotificationTypeKeys.ERROR]: markRaw(_sfc_main$8),
  [NotificationTypeKeys.INFO]: markRaw(_sfc_main$7),
  [NotificationTypeKeys.WARNING]: markRaw(_sfc_main$8),
  [NotificationTypeKeys.PROMISE]: markRaw(_sfc_main$3),
  [NotificationTypeKeys.PROMISE_RESOLVE]: markRaw(_sfc_main$9),
  [NotificationTypeKeys.PROMISE_REJECT]: markRaw(_sfc_main$8),
  close: markRaw(_sfc_main$2)
};
const outlinedIcons = {
  [NotificationTypeKeys.SUCCESS]: markRaw(_sfc_main$6),
  [NotificationTypeKeys.ERROR]: markRaw(_sfc_main$5),
  [NotificationTypeKeys.INFO]: markRaw(_sfc_main$4),
  [NotificationTypeKeys.WARNING]: markRaw(_sfc_main$5),
  [NotificationTypeKeys.PROMISE]: markRaw(_sfc_main$3),
  [NotificationTypeKeys.PROMISE_RESOLVE]: markRaw(_sfc_main$6),
  [NotificationTypeKeys.PROMISE_REJECT]: markRaw(_sfc_main$5),
  close: markRaw(_sfc_main$2)
};
const layout = {
  "--nv-width": "350px",
  "--nv-spacing": "0.625rem",
  "--nv-radius": "0.625rem",
  "--nv-icon-size": "1.25rem",
  "--nv-title-size": "0.925rem",
  "--nv-message-size": "0.925rem",
  "--nv-y-align": "center"
};
const shadow = {
  "--nv-shadow": "rgba(0, 0, 0, 0.06) 0px 4px 6px -1px, rgba(0, 0, 0, 0.03) 0px 2px 4px -1px"
};
const lightTheme = __spreadProps(__spreadValues(__spreadValues({}, layout), shadow), {
  "--nv-global-bg": "#FFF",
  "--nv-global-fg": "#171717",
  "--nv-success-accent": "#28B780",
  "--nv-error-accent": "#E74C3C",
  "--nv-warning-accent": "#F59E0B",
  "--nv-info-accent": "#3E8EFF",
  "--nv-promise-accent": "#171717"
});
const pastelTheme = __spreadProps(__spreadValues(__spreadValues({}, layout), shadow), {
  "--nv-success-bg": "#E9FAEF",
  "--nv-success-accent": "#059669",
  "--nv-success-fg": "#057452",
  "--nv-error-bg": "#FEEFEF",
  "--nv-error-accent": "#E6523C",
  "--nv-error-fg": "#C5412C",
  "--nv-warning-bg": "#FFF0D8",
  "--nv-warning-accent": "#F48533",
  "--nv-warning-fg": "#81471D",
  "--nv-info-bg": "#DEF0FA",
  "--nv-info-accent": "#1F70AC",
  "--nv-info-fg": "#1F70AC",
  "--nv-promise-bg": "#FFF",
  "--nv-promise-accent": "#334155",
  "--nv-promise-fg": "#334155"
});
const materialTheme = __spreadProps(__spreadValues(__spreadValues({}, layout), shadow), {
  "--nv-global-accent": "#FFF",
  "--nv-global-fg": "#FFF",
  "--nv-success-bg": "#178570",
  "--nv-error-bg": "#C94430",
  "--nv-info-bg": "#117AAE",
  "--nv-warning-bg": "#FFE556",
  "--nv-warning-fg": "#4F5358",
  "--nv-warning-accent": "#4F5358",
  "--nv-promise-bg": "#FFF",
  "--nv-promise-fg": "#334155",
  "--nv-promise-accent": "#64748B"
});
const darkTheme = __spreadProps(__spreadValues({}, layout), {
  "--nv-border-width": "1px",
  "--nv-global-bg": "#1F1F1F",
  "--nv-global-border": "#414141",
  "--nv-global-fg": "#D0D0D0",
  "--nv-success-accent": "#8EF997",
  "--nv-error-accent": "#FF7777",
  "--nv-warning-accent": "#FFE554",
  "--nv-info-accent": "#5FD4FF",
  "--nv-promise-accent": "#D0D0D0"
});
const slateTheme = __spreadProps(__spreadValues({}, layout), {
  "--nv-border-width": "1px",
  "--nv-global-bg": "#20252E",
  "--nv-global-border": "#353b45",
  "--nv-global-fg": "#dfdfdf",
  "--nv-success-accent": "#34D399",
  "--nv-error-accent": "#FF7777",
  "--nv-warning-accent": "#FFE554",
  "--nv-info-accent": "#5FD4FF",
  "--nv-promise-accent": "#D0D0D0"
});
const Classes = {
  NOTIFICATION: CLASS_PREFIX + "notification",
  ICON: CLASS_PREFIX + "icon",
  CONTENT: CLASS_PREFIX + "content",
  TITLE: CLASS_PREFIX + "content-title",
  MESSAGE: CLASS_PREFIX + "content-message",
  CLOSE: CLASS_PREFIX + "close",
  CLOSE_ICON: CLASS_PREFIX + "close-icon",
  TRANSITION: CLASS_PREFIX + "transition",
  PROGRESS: CLASS_PREFIX + "progress",
  DUPLICATE: CLASS_PREFIX + "duplicate"
};
const DEFAULT_NOTIFICATIONS_PROPS = {
  icons: () => filledIcons,
  theme: () => lightTheme,
  hideClose: false,
  closeAriaLabel: "Close"
};
const _hoisted_1 = ["data-notivue", "data-notivue-has-title"];
const _hoisted_2 = ["aria-live", "role"];
const _hoisted_3 = ["textContent"];
const _hoisted_4 = ["textContent"];
const _hoisted_5 = ["aria-label"];
const _hoisted_6 = ["textContent"];
const _sfc_main$1 = /* @__PURE__ */ defineComponent({
  __name: "Notification",
  props: /* @__PURE__ */ mergeDefaults({
    item: {},
    icons: {},
    theme: {},
    closeAriaLabel: {},
    hideClose: { type: Boolean }
  }, DEFAULT_NOTIFICATIONS_PROPS),
  setup(__props) {
    const props = __props;
    const Icon = shallowRef(props.icons[props.item.type]);
    const Close = props.icons.close;
    watch(
      () => props.item.type,
      (t) => Icon.value = props.icons[t],
      { flush: "sync" }
    );
    return (_ctx, _cache) => {
      return openBlock(), createElementBlock("div", {
        class: normalizeClass([unref(Classes).NOTIFICATION, { [unref(Classes).DUPLICATE]: _ctx.item.duplicateCount > 0 }]),
        key: _ctx.item.duplicateCount,
        "data-notivue": _ctx.item.type,
        "data-notivue-has-title": Boolean(_ctx.item.title),
        style: normalizeStyle(_ctx.theme)
      }, [
        Icon.value ? (openBlock(), createElementBlock(Fragment, { key: 0 }, [
          typeof Icon.value === "object" ? (openBlock(), createBlock(Transition, {
            key: 0,
            name: unref(Classes).TRANSITION,
            mode: "out-in"
          }, {
            default: withCtx(() => [
              typeof Icon.value === "object" ? (openBlock(), createBlock(resolveDynamicComponent(Icon.value), {
                key: 0,
                class: normalizeClass(unref(Classes).ICON),
                "aria-hidden": "true"
              }, null, 8, ["class"])) : createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["name"])) : typeof Icon.value === "string" ? (openBlock(), createElementBlock("div", {
            key: 1,
            class: normalizeClass(unref(Classes).ICON),
            "aria-hidden": "true"
          }, toDisplayString(Icon.value), 3)) : createCommentVNode("", true)
        ], 64)) : createCommentVNode("", true),
        createElementVNode("div", {
          class: normalizeClass(unref(Classes).CONTENT),
          "aria-live": _ctx.item.ariaLive,
          role: _ctx.item.ariaRole,
          "aria-atomic": "true"
        }, [
          _ctx.item.title ? (openBlock(), createElementBlock("h3", {
            key: 0,
            class: normalizeClass(unref(Classes).TITLE),
            textContent: toDisplayString(unref(_ctx.item.title))
          }, null, 10, _hoisted_3)) : createCommentVNode("", true),
          createElementVNode("p", {
            class: normalizeClass(unref(Classes).MESSAGE),
            textContent: toDisplayString(unref(_ctx.item.message))
          }, null, 10, _hoisted_4)
        ], 10, _hoisted_2),
        !props.hideClose && unref(Close) && _ctx.item.type !== "promise" ? (openBlock(), createElementBlock("button", {
          key: 1,
          class: normalizeClass(unref(Classes).CLOSE),
          "aria-label": _ctx.closeAriaLabel,
          type: "button",
          tabindex: "-1",
          onClick: _cache[0] || (_cache[0] = //@ts-ignore
          (...args) => _ctx.item.clear && _ctx.item.clear(...args))
        }, [
          typeof unref(Close) === "object" ? (openBlock(), createBlock(resolveDynamicComponent(unref(Close)), {
            key: 0,
            class: normalizeClass(unref(Classes).CLOSE_ICON)
          }, null, 8, ["class"])) : typeof unref(Close) === "string" ? (openBlock(), createElementBlock("div", {
            key: 1,
            "aria-hidden": "true",
            textContent: toDisplayString(unref(Close))
          }, null, 8, _hoisted_6)) : createCommentVNode("", true)
        ], 10, _hoisted_5)) : createCommentVNode("", true),
        renderSlot(_ctx.$slots, "default")
      ], 14, _hoisted_1);
    };
  }
});
const _sfc_main = /* @__PURE__ */ defineComponent({
  __name: "NotificationProgress",
  props: {
    item: {}
  },
  setup(__props) {
    const { isStreamPaused } = useNotivue();
    return (_ctx, _cache) => {
      return Number.isFinite(_ctx.item.duration) ? (openBlock(), createElementBlock("div", {
        key: _ctx.item.duplicateCount,
        class: normalizeClass(unref(Classes).PROGRESS),
        style: normalizeStyle({
          animationPlayState: unref(isStreamPaused) ? "paused" : "running",
          "--nv-anim-dur": `${_ctx.item.duration}ms`
        })
      }, null, 6)) : createCommentVNode("", true);
    };
  }
});
export {
  DEFAULT_CONFIG,
  _sfc_main$1 as Notification,
  _sfc_main as NotificationProgress,
  _sfc_main$1 as Notifications,
  _sfc_main as NotificationsProgress,
  _sfc_main$e as Notivue,
  _sfc_main$d as NotivueAstro,
  _sfc_main$a as NotivueKeyboard,
  _sfc_main$c as NotivueSwipe,
  createNotivue$1 as createNotivue,
  createNotivue as createNotivueAstro,
  darkTheme,
  filledIcons,
  lightTheme,
  materialTheme,
  outlinedIcons,
  pastelTheme,
  push$1 as push,
  push as pushAstro,
  slateTheme,
  startInstance,
  stopInstance,
  updateConfig,
  useNotifications,
  useNotivue,
  useNotivueInstance,
  useNotivueKeyboard,
  usePush
};
